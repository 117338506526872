<template>
  <footer class="blog-footer">
    <p>cloudbook.lucadibattista.it by <a href="https://lucadibattista.it">Luca Dibattista</a></p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  components: {
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:700,900&display=swap");

.blog-footer {
  padding: 2.5rem 0;
  color: #727272;
  text-align: center;
  background-color: #f9f9f9;
  border-top: .05rem solid #e5e5e5;
}
.blog-footer p:last-child {
  margin-bottom: 0;
}
</style>
