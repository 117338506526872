<template>
  <section>
    <div class="row g-5">
      <div v-if="posts === null" class="col-md-8 text-center p-5">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else class="col-md-8">
        <div v-for="post in posts" :key="'post-' + post.id" class="border-bottom">
          <div class="clearfix">
            <h3 class="fst-italic float-start">
              {{ post.title }}
            </h3>
            <div class="float-end" style="font-size: 2rem">
              <button class="btn btn-outline-success me-2" @click="votePost($event, 1, post.id)" :disabled="post.loading_upvote">
                <div v-if="post.loading_upvote" class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <i v-else class="bi bi-arrow-up"></i> {{ post.votes.up }}
              </button>
              <button class="btn btn-outline-danger" @click="votePost($event, -1, post.id)" :disabled="post.loading_downvote">
                <div v-if="post.loading_downvote" class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <i v-else class="bi bi-arrow-down"></i> {{ post.votes.down }}
              </button>
            </div>
          </div>
          {{ post.content }}
          <iframe v-if="post.youtube_video" width="560" height="315" style="max-width:100%" :src="`https://www.youtube.com/embed/${post.youtube_video}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

      </div>

      <div class="col-md-4">
        <div class="position-sticky" style="top: 2rem;">
          <div class="p-4 mb-3 bg-light rounded">
            <h4 class="fst-italic">Post something!</h4>
            Do you want to post a video? Just include the YouTube link into the message!
            <form @submit="createPost">
              <div class="mb-3">
                <label for="post-title" class="form-label">Title</label>
                <input type="text" class="form-control" id="post-title" required v-model="newPostForm.title">
              </div>
              <div class="mb-3">
                <label for="post-content" class="form-label">Message</label>
                <textarea class="form-control" id="post-content" rows="3" required v-model="newPostForm.content"></textarea>
              </div>
              <div class="mb-3">
                <button type="submit" class="btn btn-primary mb-3" :disabled="newPostForm.loading">
                  <span class="spinner-border spinner-border-sm mr-4" role="status" aria-hidden="true" v-if="newPostForm.loading"></span>
                  Submit post
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Posts',
  components: {
  },
  data() {
    return {
      rawPosts: null,
      newPostForm: {
        loading: false,
        title: '',
        content: ''
      },
    }
  },
  computed: {
    posts() {
      if (this.rawPosts === null) return null
      return [...this.rawPosts].sort((a, b) => b.datetime.localeCompare(a.datetime))
    }
  },
  async created() {
    this.fetchPosts()
  },
  methods: {
    getPostById: function (postId) {
      for (const post of this.posts) {
        if (post.id == postId) return post
      }
      return null
    },
    fetchPosts: async function () {
      try {
        var youtubeRegex = /^.*(youtu.be\/|youtube(-nocookie)?.com\/(v\/|.*u\/\w\/|embed\/|.*v=))([\w-]{11}).*/s;
        let request = await axios.get('https://social-network-api.generalassignmentworker.workers.dev/posts')
        this.rawPosts = request.data.posts.map(post => {

          // Check if there is a YouTube link into the content
          var youtubeMatch = post.content.match(youtubeRegex)
          if (youtubeMatch && youtubeMatch[4]?.length == 11) {
            post['youtube_video'] = youtubeMatch[4]
          }

          return post
        })
      } catch (e) {
        this.rawPosts = []
        alert(e)
      }
    },
    createPost: async function (event) {
      event.preventDefault()
      this.newPostForm.loading = true

      try{
        let request = await axios.post(
          'https://social-network-api.generalassignmentworker.workers.dev/posts',
          this.newPostForm
        )
        this.rawPosts.push({
          id: request.data.id,
          datetime: new Date().toISOString(),
          ...this.newPostForm,
          votes: {
            up: 0,
            down: 0
          }
        })
      } catch (e) {
        alert(e)
      }
      this.newPostForm.loading = false
    },
    votePost: async function (e, vote, postId) {
      e.preventDefault()
      let post = this.getPostById(postId)

      if (post) {
        // Show loading spinner on the button
        if (vote == 1) post.loading_upvote = true
        else post.loading_downvote = true

        try {
          await axios.post(
            'https://social-network-api.generalassignmentworker.workers.dev/vote-post',
            {
              id: postId,
              vote
            }
          )
          
          // Update votes count
          if (vote == 1) {
            post.votes.up += 1
          } else {
            post.votes.down += 1
          }
        } catch (e) {
          alert(e)
        }

        // Hide loading spinner from the button
        if (vote == 1) post.loading_upvote = false
        else post.loading_downvote = false
      }
    }
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:700,900&display=swap");

h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display", Georgia, "Times New Roman", serif/*rtl:Amiri, Georgia, "Times New Roman", serif*/;
}

.display-4 {
  font-size: 2.5rem;
}
@media (min-width: 768px) {
  .display-4 {
    font-size: 3rem;
  }
}

.card-img-right {
  height: 100%;
  border-radius: 0 3px 3px 0;
}

.flex-auto {
  flex: 0 0 auto;
}

.h-250 { height: 250px; }
@media (min-width: 768px) {
  .h-md-250 { height: 250px; }
}

/* Pagination */
.blog-pagination {
  margin-bottom: 4rem;
}
.blog-pagination > .btn {
  border-radius: 2rem;
}

/*
 * Blog posts
 */
.blog-post {
  margin-bottom: 4rem;
}
.blog-post-title {
  margin-bottom: .25rem;
  font-size: 2.5rem;
}
.blog-post-meta {
  margin-bottom: 1.25rem;
  color: #727272;
}
</style>
