import { createWebHashHistory, createRouter } from "vue-router";
import Posts from "@/views/Posts.vue";

const routes = [
  {
    path: "/",
    name: "Posts",
    component: Posts,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;